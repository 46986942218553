const RowNotImplemented = () => {
  return(
    <tr className="empty-table-row">
      <td colSpan={6}>
        <img className="img-fluid" src={I18n.t("views.dashboard.page_rank.banners.not_implemented")}/>
      </td>
    </tr>
  );
}

export default RowNotImplemented;
