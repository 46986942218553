import { useState } from "react";

import axios from "@/lib/axios";

import parse from "html-react-parser";
import swal from "sweetalert";

import * as S from "../style";

const CreateDefaultFlow = ({ pageUrl }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleNewFlow = () => {
    setIsLoading(true);

    const payload = { page_url: pageUrl };

    axios.post("/page-ranks/analytics/create-script", payload)
      .then(({ data }) => {
        swal(I18n.t("views.dashboard.page_rank.create_flow_modal.success_create_flow"), "", "success")
          .then(() => window.location.replace(`/scripts/${data.id}/edit`));
      })
      .catch(() => {
        swal(I18n.t("request_feedback_alert.error.title"), I18n.t("request_feedback_alert.error.message"), "error");
        setIsLoading(false);
      });
  };

  return (
    <div className="card card-sm">
      <S.HeaderImage urlImage="https://cdn.leadster.com.br/images/dashboard/Imagem%20novo%20fluxo.png" />

      <div className="card-body">
        <div className="fs-3 fw-medium d-flex align-items-center mb-2">
          <i className="ti ti-message-plus icon text-primary me-2" />
          {I18n.t("views.dashboard.page_rank.create_flow_modal.new_flow.title")}
        </div>

        <p className="small">
          {parse(I18n.t("views.dashboard.page_rank.create_flow_modal.new_flow.description_html"))}
        </p>

        <button
          type="button"
          className="btn btn-outline-primary w-100"
          onClick={handleNewFlow}
          disabled={isLoading}
        >
          {isLoading && <span className="spinner-border spinner-border-sm me-2 flex-shrink-0" />}
          {I18n.t("shared.actions.create_flow")}
        </button>
      </div>
    </div>
  );
};

export default CreateDefaultFlow;
