import { useEffect, useRef, useCallback } from "react";

const useInfiniteScroll = ({ callback, threshold = 1.0, enabled = true }) => {
  const targetRef = useRef(null);
  const observerRef = useRef(null);

  const memoizedCallback = useCallback(callback, [callback]);

  useEffect(() => {
    if (!enabled || !targetRef.current) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          memoizedCallback();
        }
      },
      { threshold }
    );

    observer.observe(targetRef.current);
    observerRef.current = observer;

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [memoizedCallback, threshold, enabled]);

  return { targetRef };
};

export default useInfiniteScroll;
