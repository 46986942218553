import * as S from "./style";

const RowWhatsapp = () => {
  return (
    <>
      {[...Array(10)].map((_, index) => (
        <tr key={index}>
          <td>
            <S.SkeletonCell className="w-75"></S.SkeletonCell>
          </td>
          <td>
            <S.SkeletonCell className="w-50"></S.SkeletonCell>
          </td>
          <td>
            <S.SkeletonCell className="w-50"></S.SkeletonCell>
          </td>
          <td>
            <S.SkeletonCell className="w-75"></S.SkeletonCell>
          </td>
          <td>
            <S.SkeletonCell className="w-50"></S.SkeletonCell>
          </td>
          <td>
            <S.SkeletonCell className="w-100"></S.SkeletonCell>
          </td>
        </tr>
      ))}
    </>
  )
};

export default RowWhatsapp;
