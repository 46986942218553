import { useState } from "react";

import Pagination from "@/components/shared/Pagination";

const PaginatedTable = ({
  data = [],
  columns = [],
  renderRow = () => {},
  perPageRange = [5, 10, 20],
  isPageRangeEnabled = true,
  className="card",
  tableClassName="table table-striped table-outline table-vcenter text-nowrap table-sm card-table",
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(perPageRange[0]);

  const totalPages = Math.ceil(data.length / perPage);
  const indexOfLastItem = currentPage * perPage;
  const indexOfFirstItem = indexOfLastItem - perPage;
  const currentData = data.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerPageChange = (perPage) => {
    setPerPage(perPage);
    setCurrentPage(1); 
  };

  return (
    <div className={className}>
      <table className={tableClassName}>
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th key={index} className={column.className}>
                {column.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentData.map((item, index) => renderRow(item, index))}
        </tbody>
      </table>

      <div className="ms-2">
        <Pagination
          isPageRangeEnabled={isPageRangeEnabled}
          currentPage={currentPage}
          onCurrentPageChange={handlePageChange}
          onPerPageChange={handlePerPageChange}
          initialPerPage={perPageRange[0]}
          pageCount={totalPages}
          perPage={perPageRange}
          perPageRange={perPageRange}
        />
      </div>
    </div>
  );
};

export default PaginatedTable;
