import { useEffect, useState } from "react";

const useFilters = () => {
  const filterValues = () => {
    const dateRange = $("#date-range").val();
    const [startDate, endDate] = dateRange ? dateRange.split(" - ") : [null, null];

    return {
      startDate,
      endDate,
      scriptId: $("#analytics-script").val(),
      mobile: $("input[name=mobile]:checked").val(),
      score: $("input[name='lead_score']:checked").val(),
    };
  };

  const [filters, setFilters] = useState(filterValues());

  const updateFilters = () => {
    setFilters(filterValues);
  };

  const initializeFilters = () => {
    $("#analytics-script").on("change", updateFilters);
    $("input[name='lead_score']").on("change", updateFilters);
    $("input[name=mobile]").on("change", updateFilters);
    $("#date-range").on("change", updateFilters);
  };

  useEffect(() => {
    initializeFilters();
  }, []);

  return filters;
};

export default useFilters;
