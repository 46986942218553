import axios from "axios";

const token = document.querySelector('[name="csrf-token"]') || { content: "no-csrf-token" };

const customAxios = axios.create({
  headers: {
    common: { "X-CSRF-Token": token.content },
    Accept: "application/json",
  },
});

export default customAxios;
