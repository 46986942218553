const RowTrialing = () => {
  return(
    <tr className="empty-table-row">
      <td colSpan={6}>
        <img className="img-fluid" src={I18n.t("views.dashboard.page_rank.banners.trialing")} />
      </td>
    </tr>
  );
}

export default RowTrialing;
