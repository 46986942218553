import { Modal, Button, Alert } from "react-bootstrap";

const IntegrationEventModal = ({ event, isAdmin, onClose }) => {

  return (
    <Modal show={true} onHide={onClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t("views.integrations.modal.view_event.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {event.status === 2 ? (
          <Alert variant="danger">
            <h4>{I18n.t("views.integrations.modal.view_event.text01")}</h4>
            <p style={{ whiteSpace: "normal" }}>
              {event.status_message || I18n.t("views.integrations.modal.view_event.text02")}
            </p>
            <div className="btn-list">
              <Button variant="success" href={`/integrations/resend_event/${event.id}`}>
                {I18n.t("shared.actions.resend")}
              </Button>
            </div>
          </Alert>
        ) : event.status_message ? (
          <Alert variant="info">
            <h4>{I18n.t("views.integrations.modal.view_event.text03")}</h4>
            <p style={{ whiteSpace: "normal" }}>{event.status_message}</p>
          </Alert>
        ) : null}
        {isAdmin ? (
          <Alert variant="primary" className="alert-important mb-0">
            <div className="d-flex">
              <i className="ti ti-alert-triangle icon me-2"></i>
              <div
                dangerouslySetInnerHTML={{
                  __html: I18n.t("views.integrations.description_lgpd_html"),
                }}
              ></div>
            </div>
          </Alert>
        ) : (
          <pre>
            <code>{JSON.stringify(event.payload_sended || event.payload, null, 2)}</code>
          </pre>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          {I18n.t("shared.actions.close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default IntegrationEventModal;
