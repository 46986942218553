import styled from "styled-components";

export const PageLink = styled.a.attrs({
  className: "fw-medium",
})`
  max-width: calc(var(--tblr-modal-width) - 70px);
  overflow-wrap: break-word;
  font-size: 0.9rem;
  display: inline-block;
`;

export const HeaderImage = styled.div.attrs({
  className: "img-responsive img-responsive-21x9 card-img-top",
})`
  background-image: url(${({ urlImage }) => urlImage});
`;
