import { useState, useEffect } from "react";

import IntegrationEventModal from "./IntegrationEventModal";
import PaginatedTable from "./PaginatedTable";
import PermissionsGate from "../permissions/PermissionsGate";

import usePagination from "@/hooks/usePagination";
import useCurrentUserStore from "@/store/currentUserStore";
import dayjs from "@/lib/dayjs";


const IntegrationsTabs = () => {
  const [activeTab, setActiveTab] = useState("active");
  const [selectedEvent, setSelectedEvent] = useState(null);
  const { isAdministrator } = useCurrentUserStore((state) => state.currentUser);

  const {
    data: activeIntegrations,
    paginationData: activeIntegrationsPagination,
    isLoading: isLoadingActiveIntegrations,
    currentPage: activeIntegrationsPage,
    setCurrentPage: setActiveIntegrationsPage,
    perPage: activeIntegrationsPerPage,
    setPerPage: setActiveIntegrationsPerPage,
  } = usePagination("/integrations/active_integrations", {
    defaultPerPage: 1000,
    dataIndex: "data",
    defaultDataValue: [],
    enabled: activeTab === 'active',
    errorFeedbackLib: false,
  });

  const {
    data: errorEvents,
    paginationData: errorEventsPagination,
    isLoading: isLoadingErrorEvents,
    currentPage: errorEventsPage,
    setCurrentPage: setErrorEventsPage,
    perPage: errorEventsPerPage,
    setPerPage: setErrorEventsPerPage,
  } = usePagination("/integrations/error_events", {
    defaultPerPage: 10,
    dataIndex: "data",
    defaultDataValue: [],
    enabled: activeTab === 'errors',
    errorFeedbackLib: false,
  });

  useEffect(() => {
    if (activeTab === 'active') {
      setActiveIntegrationsPage(1);
    } else if (activeTab === 'errors') {
      setErrorEventsPage(1);
    }
  }, [activeTab]);

  const handleViewClick = (event) => {
    setSelectedEvent(event);
  };

  const handleModalClose = () => {
    setSelectedEvent(null);
  };

  return (
    <div>
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === "active" ? "active text-primary" : ""}`}
            onClick={() => setActiveTab("active")}
          >
            <i className="ti ti-share icon alert-icon text-primary" /> {I18n.t("views.integrations.active_integrations.title")}
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === "errors" ? "active text-primary" : ""}`}
            onClick={() => setActiveTab("errors")}
          >
            <i className="ti ti-exclamation-circle icon alert-icon text-primary" /> {I18n.t("views.integrations.error_events")}
          </button>
        </li>
      </ul>

      <div className="tab-content">
        {activeTab === "active" && (
          <div className="tab-pane active">
            <PaginatedTable
              data={activeIntegrations}
              columns={[
                { header: I18n.t("shared.actions.integration") },
                { header: I18n.t("shared.actions.identifier") },
                { header: I18n.t("shared.actions.flows") },
                { header: I18n.t("shared.actions.events") },
                { header: I18n.t("shared.actions.created_at") },
                { header: I18n.t("shared.actions.action"), className: "w-2" },
              ]}
              renderRow={(integration) => (
                <tr key={integration.id}>
                  <td>{integration.friendly_name}</td>
                  <td>
                    <span className="badge bg-green">{integration.uid.toUpperCase()}</span>
                  </td>
                  <td>
                    {integration.allowed_scripts.includes("*") ? (
                      I18n.t("shared.actions.all")
                    ) : (
                      <>
                        {integration.allowed_scripts.join(", ").length > 45 ? (
                          <span
                            className="cursor-help"
                            data-bs-toggle="popover"
                            data-bs-placement="top"
                            data-bs-html="true"
                            data-bs-trigger="hover"
                            data-bs-content={integration.allowed_scripts.join(", ")}
                          >
                            {_.truncate(integration.allowed_scripts.join(", "), { length: 45 })}
                          </span>
                        ) : (
                          integration.allowed_scripts.join(", ")
                        )}
                      </>
                    )}
                  </td>
                  <td>{integration.allowed_events.join(", ")}</td>
                  <td>{integration.created_at_in_words}</td>
                  <td>
                    <PermissionsGate scope={{ action: 'edit', subject: 'integration'}} usePopover>
                      <a
                        href={`/integrations/${integration.id}/edit`}
                        className="btn btn-sm btn-outline-primary me-2"
                        onClick={() => MixpanelService.track("integrations_access_edit")}
                      >
                        {I18n.t("shared.actions.edit")}
                      </a>
                    </PermissionsGate>
                    
                    {!integration.email_integration && (
                      <PermissionsGate scope={{ action: 'edit', subject: 'integration'}} usePopover>
                        <a
                          href={`/integrations/${integration.id}`}
                          className="btn btn-sm btn-outline-danger"
                          data-confirm={I18n.t("views.integrations.notification.confirm_integration_deletion")}
                          data-method="delete"
                          disabled={!integration.can_edit}
                          onClick={() => MixpanelService.track("integrations_access_edit")}
                        >
                          {I18n.t("shared.actions.remove")}
                        </a>
                        {integration.can_edit}
                      </PermissionsGate>
                    )}
                  </td>
                </tr>
              )}
              paginationData={activeIntegrationsPagination}
              currentPage={activeIntegrationsPage}
              setCurrentPage={setActiveIntegrationsPage}
              perPage={activeIntegrationsPerPage}
              setPerPage={setActiveIntegrationsPerPage}
              isLoading={isLoadingActiveIntegrations}
            />
          </div>
        )}

        {activeTab === "errors" && (
          <div className="tab-pane active">
            <PaginatedTable
              data={errorEvents}
              columns={[
                { header: I18n.t("shared.actions.integration") },
                { header: I18n.t("shared.actions.lead_id") },
                { header: I18n.t("shared.actions.identifier") },
                { header: I18n.t("shared.actions.state") },
                { header: I18n.t("shared.actions.created_at") },
                { header: I18n.t("shared.actions.updated_at") },
                { header: I18n.t("shared.actions.action"), className: "w-2" },
              ]}
              renderRow={(event) => (
                <tr key={event.id}>
                  <td>{event.integration_blueprint_friendly_name}</td>
                  <td>{event.lead_id}</td>
                  <td>
                    <span className="badge bg-green">{event.company_integration_uid.toUpperCase()}</span>
                  </td>
                  <td>{I18n.t("shared.actions.with_error")}</td>
                  <td>{dayjs(event.created_at).format(I18n.t("js.formats.default.date_time"))}</td>
                  <td>{dayjs(event.updated_at).format(I18n.t("js.formats.default.date_time"))}</td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-primary"
                      onClick={() => handleViewClick(event)}
                    >
                      {I18n.t("shared.actions.to_view")}
                    </button>
                  </td>
                </tr>
              )}
              paginationData={errorEventsPagination}
              currentPage={errorEventsPage}
              setCurrentPage={setErrorEventsPage}
              perPage={errorEventsPerPage}
              setPerPage={setErrorEventsPerPage}
              isLoading={isLoadingErrorEvents}
            />
          </div>
        )}
      </div>

      {selectedEvent && (
        <IntegrationEventModal
          event={selectedEvent}
          isAdmin={isAdministrator}
          onClose={handleModalClose}
        />
      )}
    </div>
  );
};

export default IntegrationsTabs;
