const RowEmpty = () => {
  return(
    <tr className="empty-table-row">
      <td className="align-middle" colSpan={6}>
        <div className="flex items-center text-center">
          <h3>{I18n.t("views.dashboard.page_rank.table.row_empty.title")}</h3>
          <p className="mt-3">{I18n.t("views.dashboard.page_rank.table.row_empty.text_1")}</p>
          <p>{I18n.t("views.dashboard.page_rank.table.row_empty.text_2")}</p>
        </div>
      </td>
    </tr>
  );
}

export default RowEmpty;
