import RowTrialing from "@/components/dashboard/PageRank/RowTrialing";
import RowEmpty from "@/components/dashboard/PageRank/RowEmpty";
import RowData from "@/components/dashboard/PageRank/RowData";
import RowError from "@/components/dashboard/PageRank/RowError";
import RowNotImplemented from "@/components/dashboard/PageRank/RowNotImplemented";
import RowWhatsapp from "@/components/dashboard/PageRank/RowWhatsapp";

import {cannot} from "@/lib/user-permission";

const RowRender = ({ pageRank, isTrialing, isImplemented, calculateRowIndex, error }) => {
  const isEmpty = pageRank.length === 0;

  if (cannot({ action: "manage", subject: "analytics_page_rank" })) {
    return <RowWhatsapp />;
  }

  if (error) {
    return <RowError />;
  }

  if (!isImplemented) {
    return <RowNotImplemented />;
  }

  if (isEmpty && isTrialing) {
    return <RowTrialing />;
  }

  if (isEmpty) {
    return <RowEmpty />;
  }

  return (
    <>
      {pageRank.map((item, i) => (
        <RowData
          key={i}
          rowIndex={i + calculateRowIndex()}
          pageRank={item}
        />
      ))}
    </>
  );
};

export default RowRender;
