import { useState } from "react";

import ReactPortal from "@/components/ReactPortal";
import ScriptToDuplicate from "./ScriptToDuplicate";

import Modal from "react-bootstrap/Modal";

import {classBinding} from "@/utils/helpers";
import axios from "@/lib/axios";

import parse from "html-react-parser";
import swal from "sweetalert";

import * as S from "../style";

const DuplicateFlow = ({ pageUrl }) => {
  const [showScriptModal, setShowScriptModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [allowedScripts, setAllowedScripts] = useState([]);

  const handleLoadScripts = () => {
    setIsLoading(true);

    setShowScriptModal(true);

    axios.get('/page-ranks/list-scripts-to-duplicate')
      .then(({ data }) => setAllowedScripts(data.scripts))
      .catch(() => {
        setAllowedScripts([]);
        swal(
          I18n.t("request_feedback_alert.error.title"),
          I18n.t("request_feedback_alert.error.message"),
          "error"
        );
      })
      .then(() => setIsLoading(false));
  };

  return (
    <div className="card card-sm">
      <S.HeaderImage urlImage={I18n.t("views.dashboard.page_rank.create_flow_modal.img.duplicate_flow")} />

      <div className="card-body">
        <div className="fs-3 fw-medium d-flex align-items-center mb-2">
          <i className="ti ti-edit icon text-primary me-2" />
          {I18n.t("views.dashboard.page_rank.create_flow_modal.duplicate_flow.title")}
        </div>

        <p className="small">
          {parse(I18n.t("views.dashboard.page_rank.create_flow_modal.duplicate_flow.description_html"))}
        </p>

        <button
          type="button"
          className="btn btn-outline-primary w-100"
          onClick={handleLoadScripts}
        >
          {I18n.t("shared.actions.duplicate_flow")}
        </button>
      </div>

      <ReactPortal>
        <Modal
          show={showScriptModal}
          className="modal-blur"
          size="xl"
          centered
          onHide={() => setShowScriptModal(false)}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <div>
              <div className="modal-title mb-1">
                {I18n.t("views.dashboard.page_rank.create_flow_modal.title")}
              </div>
              <div className="text-muted">
                {I18n.t("views.dashboard.page_rank.create_flow_modal.subtitle")}{" "}
                <S.PageLink href={pageUrl} target="_blank">
                  {pageUrl}
                </S.PageLink>
              </div>
            </div>
          </Modal.Header>

          <Modal.Body className="overflow-auto" style={{ maxHeight: 500}}>
            <div
              className={classBinding({ "content-loading": isLoading }, "modal-body")}
              style={{ minHeight: 400, maxHeight: 500 }}
            >
              {allowedScripts.map((script) => (
                <ScriptToDuplicate key={script.id} pageUrl={pageUrl} script={script} />
              ))}
            </div>
          </Modal.Body>
        </Modal>
      </ReactPortal>
    </div>
  );
};

export default DuplicateFlow;
