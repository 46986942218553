import { useState } from "react";

import ReactPortal from "@/components/ReactPortal";
import DuplicateFlow from "./DuplicateFlow";
import CreateDefaultFlow from "./CreateDefaultFlow";

import { Modal, Row, Col } from "react-bootstrap";

import * as Styled from "./style";

const FlowOptions = ({ pageRank }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {pageRank.already_has_script ? (
        <a
          className="btn btn-outline-warning"
          href={`/scripts/${pageRank.already_has_script}/edit`}
          style={{ minWidth: "115px" }}
        >
          {I18n.t("shared.actions.edit_flow")}
        </a>
      ) : (
        <a
          className="btn btn-outline-primary"
          onClick={() => setShowModal(true)}
          style={{ minWidth: "115px" }}
        >
          {I18n.t("shared.actions.create_flow")}
        </a>
      )}

      <ReactPortal>
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          className="modal-blur"
          backdrop="static"
          centered
          size="lg"
          scrollable
        >
          <Modal.Header closeButton className="py-3">
            <div>
              <Modal.Title className="mb-1">
                {I18n.t("views.dashboard.page_rank.create_flow_modal.title")}
              </Modal.Title>
              <div className="text-muted">
                {I18n.t("views.dashboard.page_rank.create_flow_modal.subtitle")}{" "}
                <Styled.PageLink href={pageRank.url} target="_blank">
                  {pageRank.url}
                </Styled.PageLink>
              </div>
            </div>
          </Modal.Header>

          <Modal.Body>
            <Row className="row-deck justify-content-center g-3">
              <Col xl={5} lg={4}>
                <CreateDefaultFlow pageUrl={pageRank.url} />
              </Col>
              <Col xl={5} lg={4}>
                <DuplicateFlow pageUrl={pageRank.url} />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </ReactPortal>
    </>
  );
};

export default FlowOptions;
