import FlowOptions from "./FlowOptions";

import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { classBinding } from "@/utils/helpers";

const RowData = ({ rowIndex, pageRank }) => {
  return (
    <tr>
      <td className="text-center w-1">{rowIndex}</td>
      <td className="truncate-url">
        <OverlayTrigger overlay={<Tooltip>{pageRank.url}</Tooltip>}>
          <a href={pageRank.url} target="_blank">
            {_.truncate(pageRank.url, { length: 60 })}
          </a>
        </OverlayTrigger>
      </td>

      <td className="text-center w-1">
        <span className="fw-medium">{pageRank.access}</span>
      </td>

      <td className="text-center w-1">
        <span
          className={classBinding(
            {
              "text-success": pageRank.conversion_rate >= 5,
              "text-primary": pageRank.conversion_rate >= 3 && pageRank.conversion_rate < 5,
              "text-warning": pageRank.conversion_rate > 1 && pageRank.conversion_rate < 3,
              "text-danger": pageRank.conversion_rate <= 1,
            }, "fw-bolder")}>
            {pageRank.conversion_rate}%
          </span>
      </td>

      <td className="text-center w-1">
        {pageRank.already_has_script ? I18n.t("shared.actions.true") : I18n.t("shared.actions.false")}
      </td>

      <td className="text-center w-1">
        <FlowOptions pageRank={pageRank} />
      </td>
    </tr>
  );
};

export default RowData;
